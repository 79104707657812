import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desconexion: {
      overflow: 'hidden',
    },
    header: {
      display: 'flex',
      padding: '1.25rem 3.125rem',
      [theme.breakpoints.down('sm')]: {
        padding: '1.25rem 1.25rem .75rem .75rem',
        alignItems: 'center',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      paddingBottom: '0',
      background: 'linear-gradient(180deg, var(--secundario-gris-azulado) 12%, var(--blanco) 108%)',
    },
  })
);

export default useStyles;
