import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'store';
import useStyles from './HeaderStyles';

const Header = ({ className }: { className?: string }) => {
  const styles = useStyles();
  const logo = useSelector((state: RootState) => state.casaCerrada.distribuidora);
  return (
    <header className={className}>
      <Link className={styles.logo} to="/">
        <img src={logo.urlLogo} alt="" />
      </Link>
    </header>
  );
};

Header.defaultProps = {
  className: '',
};
export default Header;
