import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      width: '160px',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      marginRight: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '110px',
        height: '36px',
      },
    },
    outlined: {
      color: 'var(--primario-morado)',
      backgroundColor: 'var(--blanco)',
      border: '2px solid var(--primario-morado)',
      borderWidth: '2px',
      '&:hover': {
        color: 'var(--primario-morado)',
        border: '2px solid var(--primario-morado)',
        backgroundColor: 'var(--hover-secundary)',
      },
    },
  })
);

export default useStyles;
