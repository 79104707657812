import { format, isValid, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

const FormatoFecha = ({ fecha }: { fecha: string }) => {
  const fechaLimite = isValid(parseISO(fecha)) ? parseISO(fecha) : null;
  return (
    <>
      {fechaLimite
        ? format(fechaLimite, `dd 'de' MMMM 'a las' ${fechaLimite.getUTCHours()}:${fechaLimite.getUTCMinutes()}'hrs'`, {
            locale: es,
          })
        : null}
    </>
  );
};

export default FormatoFecha;
