import axios from 'axios';

const mostrarLoading = () => {
  let contador: string | number | null = sessionStorage.getItem('loading');
  contador = parseInt(contador!, 10) + 1;
  sessionStorage.setItem('loading', contador.toString());
  const loading = document.getElementById('loading');
  loading?.classList.add('backdrop--show');
};

const AuthInterceptor = () => {
  const apiKey = `${process.env.REACT_APP_HEADER_API_KEY}`;
  axios.interceptors.request.use(request => {
    request.headers['x-api-key'] = apiKey;
    mostrarLoading();
    return request;
  });
};

export default AuthInterceptor;
