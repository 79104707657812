import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enCasaCerrada from './en/casaCerrada.json';
import enCommon from './en/common.json';
import enErrors from './en/errors.json';
import esCasaCerrada from './es/casaCerrada.json';
import esCommon from './es/common.json';
import esErrors from './es/errors.json';

export const resources = {
  en: {
    translation: enCommon,
    casaCerrada: enCasaCerrada,
    errors: enErrors,
  },
  es: {
    translation: esCommon,
    casaCerrada: esCasaCerrada,
    errors: esErrors,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'es',
  resources,
  ns: ['translation', 'casaCerrada', 'migracion'],
});
