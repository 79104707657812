import { ErrorInterceptor } from 'config';
import { CASACERRADA, ROOT } from 'navigation';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RootPage } from 'root-page';

const RootRouterConfig = () => {
  const { t } = useTranslation();

  ErrorInterceptor(t);

  return (
    <Switch>
      <Redirect path={ROOT} exact to={CASACERRADA} />
      <Route path={CASACERRADA}>
        <RootPage path={CASACERRADA} />
      </Route>
    </Switch>
  );
};

export default RootRouterConfig;
