import { Hidden } from '@material-ui/core';
import { Typography } from '@saesa/ui-kit-front';
import { Usuario } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './InfoClienteStyles';

const InfoCliente = ({ className, usuario }: { className?: string; usuario: Usuario }) => {
  /**
   * Uso de estilos
   */
  const styles = useStyles();
  /**
   * Uso del i18n para traducciones
   */
  const { t } = useTranslation();
  /**
   * Usuario vista
   */
  const [usuarioVista, setUsuarioVista] = useState(usuario);

  useEffect(() => {
    setUsuarioVista(usuario);
  }, [usuario]);

  return (
    <section className={`${className} ${styles.servicio}`}>
      <section>
        <section className={styles.numeroCliente}>
          <Hidden only={['xs', 'sm']}>
            <Typography strong="bold" className={styles.tituloCampo} variant="parrafo">
              {t('componentes.detalleServicio.numeroCliente')}
            </Typography>
          </Hidden>
          <Hidden only={['md', 'lg', 'xl']}>
            <Typography strong="bold" className={styles.tituloCampo} variant="parrafo">
              {t('componentes.detalleServicio.numeroClienteMobile')}
            </Typography>
          </Hidden>
          <Typography className={styles.tituloCampo} variant="parrafo">
            {usuarioVista.numServicio}
          </Typography>
        </section>
        {usuarioVista.comuna ? (
          <section className={styles.comuna}>
            <Typography strong="bold" className={styles.tituloCampo} variant="parrafo">
              {t('componentes.detalleServicio.comuna')}
            </Typography>
            <Typography className={styles.tituloCampo} variant="parrafo">
              {usuarioVista.comuna}
            </Typography>
          </section>
        ) : null}
        {usuarioVista.localidad ? (
          <section className={styles.localidad}>
            <Typography strong="bold" className={styles.tituloCampo} variant="parrafo">
              {t('componentes.detalleServicio.localidad')}
            </Typography>
            <Typography className={styles.tituloCampo} variant="parrafo">
              {usuarioVista.localidad}
            </Typography>
          </section>
        ) : null}
      </section>
    </section>
  );
};

InfoCliente.defaultProps = {
  className: '',
};

export default InfoCliente;
