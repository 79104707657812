import { Header } from 'components';
import { QueryParams } from 'config';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actualizarUsuario } from 'store';
import Routes from './navigation/RouterConfig';
import useStyles from './RootStyles';

const Root = ({ path }: { path: string }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  /**
   * Variable que maneja query params
   */
  const query = QueryParams();
  /**
   * Valor de numero servicio obtenido por query params
   */
  const numeroServicio = query.get('numeroServicio');

  useEffect(() => {
    dispatch(
      actualizarUsuario({
        numServicio: numeroServicio,
      })
    );
  }, [numeroServicio]);

  return (
    <section className={styles.desconexion}>
      <Header className={styles.header} />
      <section className={styles.content}>
        <Routes path={path} />
      </section>
    </section>
  );
};

export default Root;
