import jwt_decode, { JwtPayload } from 'jwt-decode';
import { createContext, ReactNode } from 'react';
import { LoginResponse } from 'services/models';

export type Auth = {
  usuario: LoginResponse | null;
  iniciarSesion: (login: LoginResponse, callback: () => void) => void;
  cerrarSesion: (callback: () => void) => void;
  usuarioAutenticado: () => boolean;
};

const obtenerUsuarioStorage = () => {
  const usuario: LoginResponse | null = {
    access_token: localStorage.getItem('token')!,
    expires_in: parseInt(localStorage.getItem('expires_in')!, 10),
    token_type: localStorage.getItem('token_type')!,
    userId: localStorage.getItem('userId')!,
  };
  return usuario;
};

let usuario: LoginResponse | null = obtenerUsuarioStorage();

const setUsuario = (nuevoUsuario: LoginResponse | null) => {
  usuario = nuevoUsuario;
};

const iniciarSesion = (login: LoginResponse, callback = () => {}) => {
  setUsuario(login);
  localStorage.setItem('token', login.access_token ? login.access_token : '');
  localStorage.setItem('expires_in', login.expires_in ? String(login.expires_in) : '');
  localStorage.setItem('token_type', login.token_type ? login.token_type : '');
  localStorage.setItem('userId', login.userId ? login.userId : '');

  callback();
};

const cerrarSesion = (callback = () => {}) => {
  setUsuario(null);
  localStorage.removeItem('token');
  localStorage.removeItem('expires_in');
  localStorage.removeItem('token_type');
  localStorage.removeItem('userId');
  callback();
};

const usuarioAutenticado = () => {
  if (usuario?.access_token) {
    const date = new Date();
    const decodedToken = jwt_decode<JwtPayload>(usuario?.access_token);
    return decodedToken.exp! * 1000 > date.getTime();
  }
  return false;
};

const auth: Auth = {
  usuario,
  iniciarSesion,
  cerrarSesion,
  usuarioAutenticado,
};

export const authContext = createContext<Auth>(auth);

const AuthProvider = ({ children }: { children: ReactNode }) => <authContext.Provider value={auth}>{children}</authContext.Provider>;

export default AuthProvider;
