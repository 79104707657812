import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagesItem: {
      width: '100%',
      maxWidth: '996px',
      display: 'flex',
      flexFlow: 'column',
      boxShadow: '0px 0.125rem 1.25rem hsla(var(--valor-primario-azul-oscuro), 0.14)',
      borderRadius: '1.25rem',
      [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 114px)',
      },
    },
    flecha: {
      '--icon-primary-dark': 'var(--primario-morado)',
      marginRight: '0.625rem',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: '1.875rem 1.75rem 1.875rem 2.375rem',
      borderBottom: '1px solid var(--secundario-gris-azulado)',
      [theme.breakpoints.down('sm')]: {
        padding: '1.125rem 5% 1.375rem 5%',
        width: '100%',
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      color: 'var(--primario-morado)',
      justifyContent: 'center',
      flexGrow: 1,
    },
    body: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1.5rem 2.375rem 5rem 2.375rem',
      [theme.breakpoints.down('sm')]: {
        padding: '1.5rem 5%',
        width: '100%',
      },
    },
    boton: {
      position: 'absolute',
    },
    ocultar: {
      display: 'none',
    },
  })
);

export default useStyles;
