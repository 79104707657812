import { Loading } from '@saesa/ui-kit-front';
import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

const RouterConfig = ({ path }: { path: string }) => {
  const PaginaCasaCerrada = lazy(() => import('pages/CasaCerrada/CasaCerrada'));

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path={path}>
          <PaginaCasaCerrada />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default RouterConfig;
