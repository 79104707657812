import { Button, Hidden } from '@material-ui/core';
import { Card, FlechaAtrasIcon, Typography } from '@saesa/ui-kit-front';
import clsx from 'clsx';
import { ETAPAS } from 'config/tipoEtapasEnum';
import { ReactNode } from 'react';
import useStyles from './PagesItemStyle';

const PagesItem = ({
  dataTestId,
  className,
  children,
  header,
  pasoActual,
  ultimoPaso,
  titulo,
  textoVolver,
  onClickVolver,
}: {
  dataTestId?: string;
  className?: string;
  children: ReactNode;
  header?: ReactNode | null;
  pasoActual: ETAPAS;
  ultimoPaso: ETAPAS;
  titulo: string;
  textoVolver?: string;
  onClickVolver?: () => void;
}) => {
  const styles = useStyles();
  return (
    <Card className={`${className} ${styles.pagesItem}`}>
      <section data-testid={dataTestId} className={styles.header}>
        <Button
          className={clsx(styles.boton, {
            [styles.ocultar]: pasoActual === ultimoPaso || !textoVolver,
          })}
          type="button"
          onClick={onClickVolver}
        >
          <Typography strong="semi-bold" variant="parrafo" className={styles.title}>
            <FlechaAtrasIcon className={styles.flecha} />
            <Hidden only={['xs', 'sm']}>{textoVolver}</Hidden>
          </Typography>
        </Button>
        <Typography strong="semi-bold" variant="h3" className={styles.title}>
          {titulo}
        </Typography>
        {header}
      </section>
      <section className={styles.body}>{children}</section>
    </Card>
  );
};

PagesItem.defaultProps = {
  dataTestId: '',
  className: '',
  textoVolver: '',
  onClickVolver: () => {},
  header: null,
};

export default PagesItem;
