import { createSlice } from '@reduxjs/toolkit';
import { ETAPAS } from 'config/tipoEtapasEnum';
import { CasaCerradaState } from './casaCerradaState.interface';

const initialState: CasaCerradaState = {
  distribuidora: {
    urlLogo: '',
  },
  servicio: {
    id: '',
  },
  usuario: {
    numServicio: '',
    codDistribuidora: '',
    comuna: '',
    localidad: '',
    estado: '',
    fecha: '',
    pasoActual: ETAPAS.PASO1,
  },
};

export const casaCerradaSlice = createSlice({
  name: 'casaCerrada',
  initialState,
  reducers: {
    actualizarLogo: (state, action) => {
      state.distribuidora.urlLogo = action.payload?.urlLogo || state.distribuidora.urlLogo;
    },
    actualizarServicio: (state, action) => {
      state.servicio.id = action.payload.servicio?.id || state.servicio.id;
    },
    actualizarUsuario: (state, action) => {
      state.usuario.numServicio = action.payload.numServicio || state.usuario.numServicio;
      state.usuario.codDistribuidora = action.payload.codDistribuidora || state.usuario.codDistribuidora;
      state.usuario.comuna = action.payload.comuna || state.usuario.comuna;
      state.usuario.localidad = action.payload.localidad || state.usuario.localidad;
      state.usuario.estado = action.payload.estado || state.usuario.estado;
      state.usuario.fecha = action.payload.fecha || state.usuario.fecha;
      state.usuario.pasoActual = action.payload.pasoActual || state.usuario.pasoActual;
    },
  },
});

export const { actualizarServicio, actualizarLogo, actualizarUsuario } = casaCerradaSlice.actions;

export default casaCerradaSlice.reducer;
